import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  & > div > button {
    border: none;
    height: 3.25rem;
  }
`;

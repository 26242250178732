import { z } from 'zod';

export const companySchema = z.object({
  id: z.number(),
  name: z
    .string()
    .trim()
    .min(1, { message: 'Client name is required' })
    .min(3, { message: 'Client name must be at least 3 characters' }),
  logoImage: z.any().nullable().optional(),
  employeeCount: z
    .number({ invalid_type_error: 'A number is required.' })
    .min(1, {
      message: 'Number of employees must be greater than zero.',
    })
    .positive({ message: 'Number of employees must be greater than zero.' })
    .int(),
  websiteUrl: z
    .string()
    .trim()
    .url({ message: 'Website URL must be a valid URL' })
    .or(z.literal('')),
  state: z.string({ required_error: 'State is required' }),
  industry: z.string().optional(),
  updatedAt: z.string(),
  managerId: z.string().optional(),
  consultantId: z.string().optional(),
});

export const insertCompanySchema = companySchema.omit({
  id: true,
  updatedAt: true,
});

export const updateCompanySchema = companySchema;

export type CompanySchema = z.infer<typeof companySchema>;

export type InsertCompanySchema = z.infer<typeof insertCompanySchema>;

export type UpdateCompanySchema = z.infer<typeof updateCompanySchema>;

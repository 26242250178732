import { AiOutlineRollback } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md';
import styled from 'styled-components';

import { BaseLoading } from '@gbs-monorepo-packages/common';
import { colors, scrollbar } from '@gbs-monorepo-packages/styles';

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: 1.5rem;
  display: flex;
  overflow: auto;
  padding: 2rem 1.5rem;
  width: fit-content;

  ${scrollbar};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const ImageSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: -5px 0px 15px 0px;
`;

export const ImageContainer = styled.div`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  flex-direction: row;
  height: 6rem;
  width: 150px;
  justify-content: space-between;
  padding: 0.5rem;
`;

export const ImageErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ImageRemoveLink = styled.a`
  font-size: 0.813rem;
  white-space: nowrap;
  margin: 5px 0;
  color: ${colors.blue300};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${colors.blue300};
  }
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  height: 1.75rem;
  width: 1.75rem;

  &:hover {
    color: ${colors.blue300};
  }
`;

export const RollbackIcon = styled(AiOutlineRollback)`
  height: 1.75rem;
  width: 1.75rem;

  &:hover {
    color: ${colors.blue300};
  }
`;

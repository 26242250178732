import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';

import { type IUserDTO, useAuth } from '@gbs-monorepo-packages/auth';
import {
  ABORT_REQUEST_REASON,
  INIT_PAGE,
  type IPaginationMetaProps,
  type IRequestAbortController,
  type ISelectFilterValue,
  LIMIT_PAGE,
  Logger,
  assertAbortReason,
  generateAbortRequest,
  isAbortError,
  useToast,
} from '@gbs-monorepo-packages/common';

import { getUserById } from '../../services/users';
import { SelectFilterCustom } from './styles';

export interface IUserWithFullName extends IUserDTO {
  fullName: string;
}

export interface ISelectManagersProps {
  onValueChange?: (id: string, data: IUserWithFullName) => void;
  onOpenChange?: (open: boolean) => void;
  dataCy?: string;
  defaultValue?: string;
}

const NotFoundIndex = -1 as const;
const NoExtraIndex = undefined;
type IExtraIndex = typeof NoExtraIndex | typeof NotFoundIndex;

export const SelectManagers = ({
  onValueChange,
  onOpenChange,
  dataCy = 'select-managers',
  defaultValue,
}: ISelectManagersProps): JSX.Element => {
  const { getManagers } = useAuth();
  const { addToast } = useToast();

  const [manager, setManager] =
    useState<ISelectFilterValue<IUserWithFullName> | null>(null);
  const [managers, setManagers] = useState<IUserWithFullName[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const forcedSelectedManagerIndex = useRef<IExtraIndex>();

  const [loadingManagers, setLoadingManagers] = useState(false);

  const lastRequestAbort = useRef<IRequestAbortController | null>(null);
  const searchManager = useRef('');

  const updateDefaultUserData = useCallback(() => {
    const defaultManagerId = managers.findIndex(
      ({ id }) => id.toString() === String(defaultValue)
    );

    if (defaultManagerId !== NotFoundIndex) {
      const managerData = managers[defaultManagerId];

      setManager({
        ...managerData,
        index: defaultManagerId,
      });
    } else {
      getUserById(Number(defaultValue))
        .then((defaultManagerData) => {
          const fullNameManager = {
            ...defaultManagerData,
            fullName: `${defaultManagerData.firstName} ${defaultManagerData.lastName}`,
          };

          setManager(fullNameManager);
          setManagers(
            produce((draft) => {
              draft.push(fullNameManager);
            })
          );
        })
        .catch((error) => {
          Logger.error('Error on getting default manager data: ', error);
          setManager(null);
        });
    }
  }, [managers, defaultValue]);

  useEffect(() => {
    if (
      (managers.length > 0 &&
        lastRequestAbort.current === null &&
        manager === null) ||
      (manager !== null && manager.id.toString() !== defaultValue)
    ) {
      updateDefaultUserData();
    }
  }, [managers, lastRequestAbort, updateDefaultUserData]);

  useEffect(() => {
    let mount = true;

    const fetchManagers = async () => {
      setLoadingManagers(true);
      try {
        const { data: newPageData, meta } = await getManagers({
          page: INIT_PAGE,
          limit: LIMIT_PAGE,
          filter: searchManager.current,
        });

        let addedExtraManager: IExtraIndex = NoExtraIndex;
        if (manager?.id !== undefined) {
          const indexManager = newPageData.findIndex(
            ({ id }) => id === manager.id
          );

          let newIndex = indexManager;
          if (indexManager === NotFoundIndex) {
            newPageData.push(manager);
            addedExtraManager = NotFoundIndex;
            newIndex = newPageData.length - 1;
          }

          if (mount) {
            setManager({
              ...manager,
              index: newIndex,
            });
          }
        }

        if (mount) {
          forcedSelectedManagerIndex.current = addedExtraManager;
          const newPageDataWithFullName: IUserWithFullName[] = newPageData.map(
            (user) => ({
              ...user,
              fullName: `${user.firstName} ${user.lastName}`,
            })
          );
          setManagers(newPageDataWithFullName);
          setPaginationMeta(meta);
        }
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting manager',
          description:
            'An error occurred. Please try again or contact Platform support.',
          styleType: 'error',
          dataCy: 'fetch-manager-error-toast',
          duration: 3000,
        });
      } finally {
        mount && setLoadingManagers(false);
      }
    };

    void fetchManagers();
    return () => {
      mount = false;
    };
  }, []);

  const searchManagers = useCallback(
    async (newFilter = false) => {
      let pageAux = 0;
      if (newFilter) {
        lastRequestAbort.current?.abort(ABORT_REQUEST_REASON);
      } else {
        if (lastRequestAbort.current?.avoidConcurrency ?? false) {
          Logger.info(
            'Skip new page request, because there is a new search request'
          );
          return;
        }
        pageAux = paginationMeta?.page ?? 0;
      }

      const newRequestAbort = generateAbortRequest(newFilter);
      lastRequestAbort.current = newRequestAbort;

      try {
        const { data: newPageData, meta } = await getManagers(
          {
            page: pageAux + 1,
            limit: LIMIT_PAGE,
            filter: searchManager.current ?? '',
          },
          {
            signal: newRequestAbort.signal,
          }
        );

        const newPageDataWithFullName: IUserWithFullName[] = newPageData.map(
          (user) => ({
            ...user,
            fullName: `${user.firstName} ${user.lastName}`,
          })
        );
        if (newFilter) {
          let addedExtraManager: IExtraIndex = NoExtraIndex;
          if (manager?.id !== undefined) {
            const indexManager = newPageDataWithFullName.findIndex(
              ({ id }) => id === manager.id
            );

            let newIndex = indexManager;
            if (indexManager === NotFoundIndex) {
              newPageDataWithFullName.push(manager);
              addedExtraManager = NotFoundIndex;
              newIndex = newPageDataWithFullName.length - 1;
            }

            setManager(
              produce((draft) => {
                if (draft) draft.index = newIndex;
              })
            );
          }

          forcedSelectedManagerIndex.current = addedExtraManager;
          setManagers(newPageDataWithFullName);
        } else {
          const extraIndex = forcedSelectedManagerIndex.current;
          if (extraIndex !== NoExtraIndex && manager?.id !== undefined) {
            const index = newPageDataWithFullName.findIndex(
              ({ id }) => id === manager.id
            );

            let offsetSelection = newPageDataWithFullName.length;
            let deleteExtra = 0;
            if (index !== NotFoundIndex) {
              deleteExtra = 1;
              forcedSelectedManagerIndex.current = NoExtraIndex;
              offsetSelection = index;
            }
            setManagers(
              produce((draft) => {
                draft.splice(
                  extraIndex,
                  deleteExtra,
                  ...newPageDataWithFullName
                );
              })
            );
            setManager(
              produce((draft) => {
                if (draft) {
                  draft.index = offsetSelection + (draft.index ?? 0);
                }
              })
            );
          } else {
            setManagers(
              produce((draft) => {
                draft.push(...newPageDataWithFullName);
              })
            );

            // if (manager === null && defaultValue) {
            //   void updateDefaultUserData();
            // }
          }
        }
        setPaginationMeta(meta);
      } catch (error) {
        if (
          isAbortError(error) &&
          assertAbortReason(newRequestAbort, ABORT_REQUEST_REASON)
        ) {
          Logger.info(
            'Request was aborted, because there is a new search request'
          );
          return;
        }
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting managers',
          description:
            'An error occurred. Please try again or contact Platform support.',
          styleType: 'error',
          dataCy: 'search-manager-error-toast',
          duration: 3000,
        });
      } finally {
        if (lastRequestAbort.current === newRequestAbort) {
          lastRequestAbort.current = null;
        }
      }
    },
    [
      paginationMeta?.page,
      getManagers,
      manager,
      defaultValue,
      updateDefaultUserData,
      addToast,
    ]
  );

  const handleSearch = useCallback(
    async (newSearch: string) => {
      searchManager.current = newSearch;
      await searchManagers(true);
    },
    [searchManagers]
  );

  const handleValueChange = useCallback(
    (newValue: ISelectFilterValue<IUserWithFullName>) => {
      setManager(newValue);
      onValueChange?.(newValue.id.toString(), newValue);
    },
    [onValueChange]
  );

  return (
    <SelectFilterCustom
      attribute="fullName"
      data={managers}
      dataCy={dataCy}
      disabled={loadingManagers}
      hasMore={managers.length < (paginationMeta?.total ?? 0)}
      next={searchManagers}
      onSearch={handleSearch}
      onValueChange={handleValueChange}
      onOpenChange={onOpenChange}
      pageSize={LIMIT_PAGE}
      placeholder="Select a manager"
      value={manager}
      zIndex={10}
    />
  );
};

import { FormBase, SearchBar, SelectData } from '@gbs-monorepo-packages/common';

import { type ITemplateType } from '../../../../constants/TemplatesTypes';
import {
  SearchBarContainer,
  SearchTemplatesContainer,
  SearchTypeContainer,
} from './styles';

export interface ISearchTemplatesProps {
  dataCyPrefix: string;
  labelPrefix: string;
  templateTypeData: ITemplateType[];
  currentTemplateType: number;
  onChangeTemplateType: (value: number) => void;
  currentSearch: string;
  onChangeSearchData: (search: string) => void;
  onClearSearchData: () => void;
  isSearching?: boolean;
}

export const SearchTemplates = ({
  dataCyPrefix,
  labelPrefix,
  templateTypeData,
  currentTemplateType,
  onChangeTemplateType,
  currentSearch,
  onChangeSearchData,
  onClearSearchData,
  isSearching = false,
}: ISearchTemplatesProps): JSX.Element => {
  return (
    <SearchTemplatesContainer>
      <SearchTypeContainer>
        <FormBase.InputContent
          filled
          label={`${labelPrefix} Type`}
          inputRef={`${dataCyPrefix}-type-select`}
        >
          <SelectData
            dataCy={`${dataCyPrefix}-type-select`}
            data={templateTypeData}
            value={currentTemplateType.toString()}
            placeholder={`
            Select ${labelPrefix} Type
          `}
            onValueChange={(value) => {
              onChangeTemplateType(Number(value));
            }}
            required
          />
        </FormBase.InputContent>
      </SearchTypeContainer>
      <SearchBarContainer>
        <SearchBar
          search={currentSearch}
          placeholder={`Search ${labelPrefix}`}
          onChange={(e) => {
            onChangeSearchData(e.target.value);
          }}
          onClearInput={onClearSearchData}
          loading={isSearching}
          dataCy={`${dataCyPrefix}-input-search`}
        />
      </SearchBarContainer>
    </SearchTemplatesContainer>
  );
};

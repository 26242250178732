import { type ReactNode } from 'react';

import { Container } from './styles';

export interface ISelectContainerProps {
  dataCy?: string;
  children: ReactNode;
}

export const SelectContainer = ({
  dataCy = 'select-container',
  children,
}: ISelectContainerProps): JSX.Element => {
  return <Container data-cy={dataCy}>{children}</Container>;
};

import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useToast } from '@gbs-monorepo-packages/common';

import {
  wrapperClass,
  wrapperCss,
} from '../../components/GrapesJS/Customs/ToggleWrapper';
import {
  type ITemplatePageDTO,
  getTemplatePage,
} from '../../services/templates';
import Logger from '../../utils/logger';
import {
  CourseViewerContainer,
  Header,
  IframeCustom,
  LoadingPages,
  LoadingPagesContainer,
  MainContainer,
  MainContent,
  NoPagesContainer,
  NoPagesText,
  NoTemplateContainer,
  NoTemplateText,
  PageIcon,
  PageIconContainer,
  PageTitle,
  PageTitleContainer,
} from './styles';

export function TemplatePreview(): JSX.Element {
  const [loadingTemplate, setLoadingTemplate] = useState<boolean>(false);
  const [template, setTemplate] = useState<ITemplatePageDTO | null>(null);
  const { addToast } = useToast();
  const { templateId = '' } = useParams();

  useEffect(() => {
    let mount = true;
    const fetchTemplatePage = async () => {
      try {
        setLoadingTemplate(true);
        const result = await getTemplatePage({ id: templateId });

        if (mount) {
          setTemplate(result);
        }
      } catch (error) {
        Logger.debug('error: ', error);
        if (
          error instanceof AxiosError &&
          (error.response?.status ?? 0) !== 404
        ) {
          addToast({
            title: 'Error on get template',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'update-image-error-toast',
          });
        }
      } finally {
        if (mount) {
          setLoadingTemplate(false);
        }
      }
    };

    void fetchTemplatePage();

    return () => {
      mount = false;
    };
  }, [addToast, templateId]);

  const isContentEmpty = !template?.htmlContent.trim();

  return (
    <CourseViewerContainer>
      <MainContainer data-cy="page-container">
        {loadingTemplate ? (
          <LoadingPagesContainer data-cy="loading-template-viewer-container">
            <LoadingPages dataCy="loading-template-viewer" />
          </LoadingPagesContainer>
        ) : !template ? (
          <NoTemplateContainer data-cy="template-not-founded">
            <NoTemplateText data-cy="template-not-founded-text">
              Template not found
            </NoTemplateText>
          </NoTemplateContainer>
        ) : (
          <>
            <Header>
              <PageIconContainer data-cy="template-icon-container">
                <PageIcon data-cy="template-icon" />
              </PageIconContainer>
              <PageTitleContainer data-cy="template-title-container">
                <PageTitle data-cy="template-title">{template.title}</PageTitle>
              </PageTitleContainer>
            </Header>
            {isContentEmpty ? (
              <NoPagesContainer data-cy="no-pages-founded">
                <NoPagesText data-cy="no-pages-founded-text">
                  Template is Empty
                </NoPagesText>
              </NoPagesContainer>
            ) : (
              <MainContent data-cy="course-viewer-content">
                <IframeCustom
                  srcDoc={`<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <meta name="x-apple-disable-message-reformatting">
                          <meta http-equiv="X-UA-Compatible" content="IE=edge">
                          <style type="text/css">${wrapperCss}</style>
                          <style type="text/css">${
                            template?.cssContent ?? ''
                          }</style>
                          <table id="gjs_body" style="table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: transparent;width:100%" cellpadding="0" cellspacing="0">
                            <tbody>
                              <tr style="vertical-align: top">
                                <td ${
                                  template.hasWrapper ?? false
                                    ? `class="${wrapperClass}"`
                                    : ''
                                } style="vertical-align: top;">
                                  ${template?.htmlContent ?? ''}
                                </td>
                              </tr>
                            </tbody>
                          </table>`}
                />
              </MainContent>
            )}
          </>
        )}
      </MainContainer>
    </CourseViewerContainer>
  );
}

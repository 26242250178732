import { useCallback, useEffect, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  BaseCheckbox,
  FormBase,
  FormModal,
  SelectData,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { type IDialogModalProps } from '../../../../components/DialogModal';
import { type ICourseSettingsProps } from '../../../../services/courses';
import {
  type CourseSettingSchema,
  courseSettingSchema,
} from '../../courseSchema';
import {
  CheckContainer,
  FieldsetCheckBox,
  LabelCheckBox,
  LockedIcon,
  UnLockedIcon,
} from './styles';

interface ISettingCourseModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  courseTitle?: string;
  onAccept: (
    passcodeProtected: boolean,
    passcode: string,
    viewURL: string,
    courseStatus: string
  ) => void;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  courseSettings: ICourseSettingsProps | null;
}

export const SettingCourseModal = ({
  courseTitle = 'this',
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  courseSettings,
  ...props
}: ISettingCourseModalProps): JSX.Element | null => {
  const isSelectOpen = useRef(new Set());
  const [passcodeProtected, setPasscodeProtected] = useState(false);
  const [showPasscode, setShowPasscode] = useState(false);

  const courseSettingSchemaModal = useForm<CourseSettingSchema>({
    resolver: zodResolver(courseSettingSchema),
    defaultValues: {
      passcode: courseSettings?.passcode ?? '',
      viewUrl: courseSettings?.viewUrl ?? '',
      status: courseSettings?.status ?? 'In Progress',
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = courseSettingSchemaModal;

  const values = watch();

  const resetForm = useCallback(() => {
    setValue('passcode', courseSettings?.passcode ?? '');
    setValue('viewUrl', courseSettings?.viewUrl ?? '');
    setValue('status', courseSettings?.status ?? 'In Progress');
  }, [
    courseSettings?.passcode,
    courseSettings?.viewUrl,
    courseSettings?.status,
    setValue,
  ]);

  useEffect(() => {
    resetForm();
  }, [courseSettings, resetForm, setValue, open]);

  const onSubmit: SubmitHandler<CourseSettingSchema> = (
    data: CourseSettingSchema
  ) => {
    onAccept?.(
      passcodeProtected,
      data.passcode ?? '',
      data.viewUrl ?? '',
      data.status ?? 'In Progress'
    );
  };

  const handleDeclineNewCourseSettings = useCallback(() => {
    if (!isSelectOpen.current.size) {
      resetForm();
      onDecline?.();
    }
  }, [resetForm, onDecline]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  return !open ? null : (
    <FormBase.Provider {...courseSettingSchemaModal}>
      <FormModal
        acceptText="Change"
        dataCy="setting-course-dialog-modal"
        declineText="Cancel"
        mainText={`${courseTitle} settings`}
        {...props}
        onAccept={handleSubmit(onSubmit)}
        onDecline={handleDeclineNewCourseSettings}
        onOpenChange={handleDeclineNewCourseSettings}
        open={!waitToOpen}
      >
        <FormBase.Content>
          <FieldsetCheckBox>
            <CheckContainer data-cy="passcode-checkbox-container">
              <BaseCheckbox
                dataCy={'passcode-checkbox'}
                name="change-passcode"
                onCheckedChange={(checked) => {
                  setPasscodeProtected(checked.valueOf() === true);
                  setValue('passcode', '');
                }}
              />
            </CheckContainer>
            <LabelCheckBox data-cy="label-checkbox">
              Change Passcode
            </LabelCheckBox>
          </FieldsetCheckBox>

          <FormBase.InputContent
            filled={!!values?.passcode}
            inputRef="passcode"
            label="Passcode"
            errorMessage={errors.passcode?.message}
            dataCy="changePassword-fieldSet"
          >
            <FormBase.InputText
              dataCy="passcode-input"
              id="passcode"
              name="passcode"
              type={showPasscode ? 'text' : 'password'}
              maxLength={20}
              autoComplete="off"
              disabled={!passcodeProtected}
            />
            <FormBase.InputActions
              icon={showPasscode ? LockedIcon : UnLockedIcon}
              onClick={() => {
                setShowPasscode(!showPasscode);
              }}
              dataCy={
                showPasscode ? 'locked-icon-confirm' : 'unlocked-icon-confirm'
              }
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.viewUrl}
            inputRef="view-url"
            label="Course Link"
            errorMessage={errors.viewUrl?.message}
            dataCy="settings-fieldSet"
          >
            <FormBase.InputText
              dataCy="view-urlInput"
              id="view-url"
              name="viewUrl"
              type="text"
              maxLength={100}
              autoComplete="off"
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.status}
            inputRef="select-state"
            label="Status"
            errorMessage={errors.status?.message}
            dataCy="label-state"
          >
            <SelectData
              data={[
                { key: 'Completed', value: 'Completed' },
                { key: 'In Progress', value: 'In Progress' },
                { key: 'Disabled', value: 'Disabled' },
              ]}
              dataCy="course-status-field"
              name="course-status"
              placeholder="Select Status"
              zIndex={10}
              defaultValue={values.status ?? 'In Progress'}
              onValueChange={(value) => {
                setValue('status', value);
              }}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'status');
              }}
            />
          </FormBase.InputContent>
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';

import {
  FormModal,
  type IBaseModalProps,
  useToast,
} from '@gbs-monorepo-packages/common';

import { SelectFonts } from '../../../../pages/EditCourse/components/SelectFonts';
import {
  type ICourseDTO,
  type IFontDTO,
  type IFontsList,
  getCourseFonts,
} from '../../../../services/courses';
import { Loading, LoadingContainer, SelectContainer } from './styles';

export interface IFont extends IFontDTO {
  selected?: boolean;
  index: number;
}

interface IAddPageModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  courseId: number;
  courseFonts: IFontDTO[];
  onAccept: (fonts: Pick<ICourseDTO, 'fonts'>) => Promise<void>;
  onDecline: () => void;
  loading?: boolean;
}

export const FontsModal = ({
  open = false,
  courseId,
  courseFonts,
  onAccept,
  onDecline,
  loading,
  ...props
}: IAddPageModalProps): JSX.Element => {
  const { addToast } = useToast();

  const [orderedCheckedFonts, setOrderedCheckedFonts] = useState<IFont[]>([]);
  const [loadingFonts, setLoadingFonts] = useState(true);
  const [loadingSaveFont, setLoadingSaveFont] = useState(false);
  const [fonts, setFonts] = useState<IFont[]>([]);
  const [selectedFontsSet, setSelectedFontsSet] = useState<Set<string>>(
    new Set()
  );
  const loadedFontsOnce = useRef(false);
  const refFormModalContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    selectedFontsSet.clear();

    courseFonts.forEach((font: IFontDTO) => {
      selectedFontsSet.add(font.family);
    });
  }, [courseFonts, selectedFontsSet]);

  useEffect(() => {
    loadedFontsOnce.current = false;
  }, [courseFonts]);

  useEffect(() => {
    let mount = true;

    const loadFontsOnce = async () => {
      loadedFontsOnce.current = true;
      setLoadingFonts(true);
      try {
        const result: IFontsList = await getCourseFonts({ id: courseId });

        setSelectedFontsSet(selectedFontsSet);

        const resultWithIndex: IFont[] = result.fonts.map((data, index) => ({
          ...data,
          selected: selectedFontsSet.has(data.family),
          index,
        }));

        setOrderedCheckedFonts(
          resultWithIndex.filter((font: IFont) => {
            return font.selected;
          })
        );

        if (mount) {
          setFonts(resultWithIndex);
        }
      } catch (err) {
        loadedFontsOnce.current = false;

        addToast({
          title: 'Error loading custom fonts',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
        });
      } finally {
        setLoadingFonts(false);
      }
    };

    if (open && courseId && !loadedFontsOnce.current) {
      void loadFontsOnce();
    }

    return () => {
      mount = false;
    };
  }, [addToast, courseFonts, courseId, open, selectedFontsSet]);

  const handleDeclineAddFonts = useCallback(() => {
    const resultWithIndex: IFont[] = fonts.map((data, index) => ({
      ...data,
      selected: selectedFontsSet.has(data.family),
      index,
    }));

    setOrderedCheckedFonts(
      resultWithIndex.filter((font: IFont) => {
        return font.selected;
      })
    );

    setFonts(resultWithIndex);

    onDecline?.();
  }, [fonts, onDecline, selectedFontsSet]);

  const handleAcceptAddFonts = useCallback(async () => {
    setLoadingSaveFont(true);

    await onAccept?.({ fonts: orderedCheckedFonts });
    setLoadingSaveFont(false);
  }, [onAccept, orderedCheckedFonts]);

  return (
    <FormModal
      {...props}
      open={open}
      acceptText="Confirm"
      dataCy="course-fonts-modal"
      declineText="Cancel"
      mainText="Select the course fonts"
      loading={loadingSaveFont || loading}
      onAccept={() => {
        void handleAcceptAddFonts();
      }}
      onDecline={handleDeclineAddFonts}
      onOpenChange={handleDeclineAddFonts}
      biggerModal
      refContent={refFormModalContent}
    >
      <p data-cy="text-selectTemplates">
        Select the fonts you would like to use.
        {` (${orderedCheckedFonts.length} selected)`}
      </p>

      <SelectContainer data-cy="template-content">
        {loadingFonts ? (
          <LoadingContainer data-cy="loading-fonts">
            <Loading />
          </LoadingContainer>
        ) : fonts.length ? (
          <SelectFonts
            defaultValue={fonts}
            onValueChange={setOrderedCheckedFonts}
            value={orderedCheckedFonts}
          />
        ) : (
          <p data-cy="text-noTemplates">No fonts</p>
        )}
      </SelectContainer>
    </FormModal>
  );
};

import { type CSSProperties } from 'react';
import styled from 'styled-components';

import { SelectFilter } from '@gbs-monorepo-packages/common';

import { type IUserWithFullName } from '.';

const selectFilterContentCustom: CSSProperties = {
  maxHeight: '25vh',
  position: 'absolute',
  width: '100%',
};

export const SelectFilterCustom = styled(SelectFilter<IUserWithFullName>).attrs(
  ({ customContentStyle = selectFilterContentCustom }) => ({
    customContentStyle,
  })
)``;
